<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title>{{$t($route.name)}}</b-card-title>
        <b-button
            class=""
            variant='success'
            @click='openModal(null, "Create")'
            v-if='resolvePermission("testmonial.create")'
          >
          <feather-icon
            icon="PlusIcon"
            size="15"
          >

          </feather-icon>
          {{$t('add new')}}
        </b-button>
    </b-card-header>
    <b-row class="mx-2">
      <b-col>
        <b-form-group
          :label='$t("Search")'
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            v-model='searchText'
          />
        </b-form-group>
      </b-col>
      <!-- <b-col>
        <b-form-group
          label="Basic Input"
          label-for="selectInput"
        >
          <b-form-select
            id='selectInput'
            v-model="selected"
            :options="options"
          />
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="options"
          />
        </b-form-group>
      </b-col> -->
      <b-col cols='1' class="align-self-center">
        <b-button 
          variant='primary'
          class="btn my-auto"
          :disabled ='loading'
          @click="filterItems"
        >
          <feather-icon
            icon='SearchIcon'
            size='16'
          ></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table
      responsive
      :items="testmonials"
      :fields="fields"
      class="mb-0"
    >
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
      <template #cell(actions)="row">
          <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
          <b-button 
            size="sm" 
            @click="editItem(row)"
            variant="flat-dark"  
            class="btn-icon"
            v-if='resolvePermission("testmonial.edit")'
          >
            <feather-icon
              icon="Edit2Icon"
              size="15"
            />
          </b-button>
          <b-button 
            size="sm" 
            @click="deleteItem(row)"
            variant="flat-danger"  
            class="btn-icon"
            v-if='resolvePermission("testmonial.delete")'
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
            />
          </b-button>
        </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import {  BTable, BButton, BRow, BCol , 
  BCard, BCardTitle, BCardHeader, BCardFooter, 
  BFormGroup, BFormInput, BFormSelect,
  BPagination, } from 'bootstrap-vue';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { mapActions, mapGetters } from 'vuex';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';  

export default {
  components: {
     BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard, BCardTitle, BCardHeader, BCardFooter,
    BFormGroup, BFormInput, BFormSelect,
    BPagination,
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('ID')
        },,
        {
          key: 'title',
          label: this.$t('name')
        },
        {
          key: 'info',
          label: this.$t('title')
        },
        {
          key: 'description',
          label: this.$t('details')
        },
        {
          key: 'status',
          label: this.$t('status')
        },
        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: 'actions',
          label: this.$t('actions')
        },
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      searchText: '',
    }
  },
  props: ['openModal', 'closeModal', 'confirmDelete', ],
  created() {
    this.getTestmonials({})
      .then(res => {
        // console.log(res)
        this.rows = res.data.meta.total
      }).catch(err => console.error(err))
  },

  methods: {
    ...mapActions(['getTestmonials', 'deleteTestmonials']),
    filterItems() {
      setTimeout(() => {
        this.getTestmonials({
          page: this.currentPage,
          keywords: this.searchText
        }).then(res => {
          this.rows = res.data.meta.total          
        }).catch(err => {
  
        })
      }, 100)
    },
    showItem(row) {
      this.openModal(row, 'Show')
      // console.log(row)
    },
    
    editItem(row) {
      this.openModal(row, 'Edit')
    },

    deleteItem(row) {
       this.confirmDelete(this.deleteTestmonials, row)
    }
  },

  computed: {
    ...mapGetters(['testmonials',]),
  }
}
</script>